import React, { Fragment } from "react";
import "@fortawesome/free-solid-svg-icons";
import { Tabs, Tab } from "react-bootstrap";
import JobDescription from "./job/JobDescription";
import Users from "./users/Users";
import Checklist from "./job/Checklist";
import Settings from "./job/Settings";
import EmailTemplates from "./job/EmailTemplates";
import AssetsCatalog from "./assets/AssetsCatalog";
import Test from "./tests/Test";
import WithdrawCatalog from "./job/withdraw_catalog/WithdrawCatalog";
import CostToCompany from "./job/cost_to_company/CostToCompany";
// import Workload from "./workload";
import Departments from "./departments/";
import Groups from "./groups";
import Category from "./categories";
import JobStatus from "./job/job_status";
import Role from "./roles/components/RolePermissionPanel";
// import EmployeeDocuments from './documentlist/EmployeeDocumentCatalogPanel';
import CatalogModule from "./catalog_module";
import Customer from "./customers/customer";
import Vendor from "./vendors";
import DroppedReasons from "./job/dropped_reasons/DroppedReasons";

// import util from "../utils/miniUtils";
import SocialMediaTokensForm from "./job/social_media_credentials/SocialMediaTokens";
import ServiceCatalog from "./ServiceCatalog";
import AccountInfo from "./EmployerSettings/AccountInfo";
import Subordinate from "./EmployerSettings/Subordinate/Subordinate";
import Employer from "./employer";
import util from "../utils/miniUtils";

function AppSettings() {
    console.log( util.arrangePermissionData() );
    return (
        <Fragment>
            {util.user.employer_id ? (
                <div className="row mt-4">
                    <div className="col-md-12">
                        <Tabs mountOnEnter={true} unmountOnExit={true} defaultActiveKey="company" >
                            <Tab eventKey="company" title="Company">
                                <AccountInfo />
                            </Tab>
                            <Tab eventKey="members" title="Members">
                                <Subordinate />
                            </Tab>
                        </Tabs>
                    </div>
                </div> ) :
                ( <div className="row mt-4">
                    <div className="col-md-12">
                        <Tabs mountOnEnter={true} unmountOnExit={true} defaultActiveKey="users" >
                            <Tab eventKey="users" title="Users">
                                <Users />
                            </Tab>
                            <Tab eventKey="job_description" title="Job" id={"job_description"}>
                                <br />
                                <Tabs mountOnEnter={true} variant={"pills"} unmountOnExit={true} defaultActiveKey="settings" >
                                    <Tab eventKey="settings" title="Setting">
                                        <Settings />
                                    </Tab>
                                    <Tab eventKey="ctc" title="Cost To Company">
                                        <CostToCompany />
                                    </Tab>
                                    <Tab eventKey="job_description" title="Job Description">
                                        <JobDescription />
                                    </Tab>
                                    <Tab eventKey="checklist" title="Checklist">
                                        <Checklist />
                                    </Tab>
                                    <Tab eventKey="email_templates" title="Email Template">
                                        <EmailTemplates />
                                    </Tab>
                                    {/*<Tab eventKey="beeline" title="Beeline Docs">*/}
                                    {/*  <Documents />*/}
                                    {/*</Tab>*/}
                                    <Tab eventKey="test" title="Test" id="testTab">
                                        <Test />
                                    </Tab>
                                    <Tab eventKey="withDraw" title="Withdraw" id="withDrawTab">
                                        <WithdrawCatalog />
                                    </Tab>
                                    <Tab eventKey="jobStatus" title="Talent Status" id="jobStatusTab">
                                        <JobStatus />
                                    </Tab>
                                    <Tab eventKey="droppedReasons" title="Dropped Reasons" id="droppedTab">
                                        <DroppedReasons />
                                    </Tab>
                                    <Tab eventKey="socialMediaTokens" title="Manage Social Media" id="socialMediaTokens">
                                        <SocialMediaTokensForm />
                                    </Tab>
                                </Tabs>
                            </Tab>
                            <Tab eventKey="assets" title="Asset">
                                <AssetsCatalog />
                            </Tab>

                            <Tab eventKey="departments" title="Departments">
                                <Departments />
                            </Tab>

                            <Tab eventKey="groups" title="Groups">
                                <Groups />
                            </Tab>
                            <Tab eventKey="workload" title="Workload">
                                <Tabs mountOnEnter={true} unmountOnExit={true} defaultActiveKey="category" >
                                    <Tab eventKey="category" title="Workload Category">
                                        <Category />
                                    </Tab>
                                
                                    <Tab eventKey="serviceCatalog" title="Service Catalog">
                                        <ServiceCatalog />
                                    </Tab>
                                </Tabs>
                            </Tab>
                            <Tab eventKey="catalog" title="Lists">
                                <CatalogModule />
                            </Tab>
                            <Tab eventKey="roles" title="Role Permissions">
                                <Role />
                            </Tab>
                            <Tab eventKey="customer" title="Customers">
                                <Customer />
                            </Tab>
                            <Tab eventKey="vendor" title="Vendors">
                                <Vendor />
                            </Tab>
                            {/* <Tab eventKey="EmployeeDocuments" title="Employee Documents"> 

                            <EmployeeDocuments /> 
                        </Tab>  */}
                            {util.arrangePermissionData()?.Employer?.includes( "Edit" ) ?
                                <Tab eventKey="employers" title="Employers">
                                    <Employer />
                                </Tab>:""}
                        </Tabs>
                    </div>
                </div> )}
        </Fragment>
    );
}

export default AppSettings;
