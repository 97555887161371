import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Form, Col, Row, Container, Card } from 'react-bootstrap';
import util from '../../utils/miniUtils';
import MemberList from './MemberList';
import CountryFillOne from '../EmployerSettings/CountryFillOne';


const EmployerDetails = ( { onBackClick,selectedEmployer } ) => {
    console.log( util.user );
    const industries = [
        "Agriculture",
        "Apparel & Fashion",
        "Automotive",
        "Aviation & Aerospace",
        "Banking & Financial Services",
        "Biotechnology",
        "Chemicals",
        "Construction",
        "Consumer Electronics",
        "Consumer Goods",
        "Defense & Space",
        "Education",
        "Electrical & Electronics",
        "Energy & Utilities",
        "Engineering Services",
        "Entertainment",
        "Environmental Services",
        "Food & Beverages",
        "Furniture",
        "Gaming",
        "Government",
        "Healthcare & Pharmaceuticals",
        "Hospitality",
        "Human Resources & Staffing",
        "Information Technology (IT)",
        "Insurance",
        "Investment & Private Equity",
        "Legal Services",
        "Logistics & Transportation",
        "Luxury Goods",
        "Manufacturing",
        "Marine",
        "Media & Publishing",
        "Mining",
        "Nonprofit & NGOs",
        "Oil & Gas",
        "Packaging",
        "Professional Services",
        "Public Safety",
        "Real Estate",
        "Renewable Energy",
        "Retail & E-commerce",
        "Security & Investigations",
        "Software Development",
        "Sports & Recreation",
        "Telecommunications",
        "Textiles & Apparel",
        "Travel & Tourism",
        "Veterinary Services",
        "Wholesale Trade",
    ];
    const isReadOnly = util.user.role === 18;
    const [showMembersTable, setShowMembersTable] = useState( false );
    const [employerDetails, setEmployerDetails] = useState( {} );
    // const [groupsList, setGroupsList] = useState( [] );
    const [loading, setLoading] = useState( false );
    const [attachmentFiles, setAttachmentFiles] = useState( [] );
    const [logoImage, setLogoImage] = useState( null );
    const [logoUrl, setLogoUrl] = useState( null ); // State to hold the fetched logo URL
    
    useEffect( () => {
        const fetchLogoUrl = async () => {
            if ( employerDetails && employerDetails.company_logo_file ) {
                const url = await util.fetchImageUrl( `src/public/employer_logos/${employerDetails.employer_id}/${employerDetails.company_logo_file}` );
                setLogoUrl( url ); // Set the fetched URL to state
            }
        };
    
        fetchLogoUrl();
    }, [employerDetails] );

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        setEmployerDetails( prev => ( { ...prev, [name]: value } ) );
    };

    const toggleMembersView = () => {
        setShowMembersTable( !showMembersTable );
    };

    const handleSave = ( e ) => {
        e.preventDefault();
        if( employerDetails.employer_id ) {
            updateEmployer( employerDetails.employer_id, employerDetails );
        } else {
            addNewEmployer( employerDetails );
        }
    };

   
    

    const getEmployerDetails = async ( id ) => {
        try {
            const request = await util.axios.get( `/employers/${id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setEmployerDetails( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

   

    const addNewEmployer = async ( employer ) => {
        try {
            const request = await util.axios.post( "/employers", { ...employer } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
        } catch ( error ) {
            util.toast().error( error );
        }
    };

    const updateEmployer = async ( id, employer ) => {
        try {
            const request = await util.axios.put( `/employers/${id}`, { ...employer } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
        } catch ( error ) {
            util.toast().error( error );
        }
    };
    function handleImgUpload( e ) {
        const file = e.target.files[0];
        if ( file ) {
            setLogoImage( file );
            console.log( "Selected file:", file.name );
        }
    }
    async function uploadLogo() {
        try {
            if ( !logoImage ) {
                util.toast().error( "Please select a logo to upload." );
                return;
            }
        
            const data = new FormData();
            data.append( "file", logoImage );
        
            // const employerId = util.user.employer_id; // Get the employer ID from the current user's data
            const request = await util.axios.post( `/employer/upload_comapny_logo/${employerDetails.employer_id}`, data );
            const { error, msg, employerData } = request.data;
        
            if ( error ) throw new Error( msg );
        
            util.toast().success( msg || "Logo uploaded successfully." );
        
            // Update the employer state with the new data
            setEmployerDetails( ( prevEmployer ) => ( {
                ...prevEmployer,
                company_logo_file: employerData.company_logo_file,
            } ) );
        
            setLogoImage( null ); // Clear the selected file
        } catch ( error ) {
            util.handleError( error );
        }
    }
        
        
    async function removeLogo() {
        try {
            // const employerId = util.user.employer_id;
            const request = await util.axios.delete( `/employer/delete_company_logo/${employerDetails.employer_id}` );
            const { error, msg } = request.data;
        
            if ( error ) throw new Error( msg );
        
            util.toast().success( msg || "Logo removed successfully." );
        
            setEmployerDetails( ( prevEmployer ) => ( {
                ...prevEmployer,
                company_logo_file: null,
            } ) );
        } catch ( error ) {
            util.handleError( error );
        }
    }

    useEffect( () => {
     
        if( selectedEmployer && selectedEmployer.employer_id ) {
            getEmployerDetails( selectedEmployer.employer_id );
        }
    },[] );

    if( loading ) {
        return util.LOADING_SCREEN( loading );
    }
    const badgeTypes = [
        'primary',
        'secondary',
       
    ];

    const badgeTexts = [
        'Active',
        'Inactive',
        
    ];

    const getBadgeText = ( number ) => {
        // Check if the input is "Active"
        const isActive = number?.toLowerCase() === 'active';
    
        // Filter the badgeTexts array based on the condition
        return badgeTexts.filter( text => {
            const lowercaseText = text.toLowerCase();
            const lowercaseNumber = number?.toLowerCase();
    
            // If the input is "Active", exclude "Inactive" from the result
            if ( isActive && lowercaseText.includes( 'inactive' ) ) {
                return false;
            }
    
            // Otherwise, include text that matches the input number
            return lowercaseText.includes( lowercaseNumber );
        } );
    };

    const getBadgeMarkup = ( badgeNumber ) => {
        // Determine the badge type based on the integer using modulus to cycle through array
        const index = badgeTexts.findIndex( text => text.toLowerCase().includes( badgeNumber?.toLowerCase() ) );

        const badgeType = badgeTypes[index];
      
        return (
            <div className="d-flex justify-content-end ">
                <span className={`badge badge-${badgeType}`}>
                    {getBadgeText( badgeNumber )}
                </span>
            </div>
        );
    };

    return (
        <>
            <div className="d-flex align-items-center mb-3">
                <Button variant="link" onClick={onBackClick} className="d-flex align-items-center mr-2">
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
                </Button>
                
                {!selectedEmployer ? "" : <Button className='btn activeBtn sizeBtn btn-secondary mr-2' onClick={toggleMembersView}>
                    {showMembersTable ? 'Info' : 'Members'}
                </Button>}
            </div>
            {showMembersTable ? <MemberList selectedEmployer={selectedEmployer} /> 
                : <div>
                    <Row style={{ marginLeft: '3%', marginTop: '20px' }}>
                        <Col xs={6} className="logo-placeholder">
                        </Col>

                    </Row>
                    <Container className="mt-3">
                        <Card className='ml-4'>
                            <Card.Header as="h5">
                                
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="d-flex align-items-center">
                                        {employerDetails.company_logo_file && (
                                            <div>
                                                <img
                                                    style={{ 
                                                        width: "50px", 
                                                        height: "50px", 
                                                        maxWidth: "150px", 
                                                        objectFit: "contain" 
                                                    }}
                                                    src={logoUrl} 
                                                    alt="logo"
                                                />
                                            </div>
                                        )}
                                        {employerDetails.company_legal_name && (
                                            <p className="mt-3 ml-2">{employerDetails.company_legal_name} Details</p>
                                        )}

                                    </span>

                                    <span>{getBadgeMarkup( employerDetails.status )}</span>
                                </div></Card.Header>
                            <Card.Body className='ml-4'>

                                <Form>
                                    {employerDetails.employer_id?
                                        <div className="mt-3">
                                            <b>Logo</b>
                                        
                                            <div>
                                                <label htmlFor="company_logo_file" className="btn btn-secondary activeBtn mt-3">
            Add Company Logo
                                                </label>
                                                <input
                                                    id="company_logo_file"
                                                    title="Logo"
                                                    accept="image/*, .svg, .webp"
                                                    onChange={handleImgUpload}
                                                    name="company_logo_file"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                />
                                                {logoImage ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary sizeBtn activeBtn ml-3"
                                                        onClick={uploadLogo}
                                                    >
                Upload
                                                    </button>
                                                ) : null}
                                            </div>

                                            {/* {employerDetails.company_logo_file ? (
                                            <div>
                                                <label>Preview</label>
                                                <img
                                                    style={{ maxWidth: "100px", marginLeft: "10px" }}
                                                    src={`${util.baseUrl()}${util.AWSURL()}/employer_logos/${employerDetails.employer_id}/${employerDetails.company_logo_file}`}
                                                    alt={employerDetails.company_legal_name}
                                                />
                                                <img
                                                    style={{ maxWidth: "100px", marginLeft: "10px" }}
                                                    src={logoUrl} 
                                                    alt={employerDetails.company_legal_name}
                                                />
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary sizeBtn activeBtn ml-2"
                                                    onClick={removeLogo}
                                                >
                                                                    Remove
                                                </button>
                                            </div>
                                        ) : null} */}
                                
                                        </div>:""}
                                    <Row className="mb-3 mt-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formCompanyName">
                                                <Form.Label>Company Legal Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="company_legal_name"
                                                    value={employerDetails.company_legal_name || ""}
                                                    placeholder="Enter your organization's name (e.g. ABC Corp.)"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formEmployerEmail">
                                                <Form.Label>Employer Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="employer_email"
                                                    value={employerDetails.employer_email || ""}
                                                    placeholder="Enter your official company email (e.g. info@abc.com)"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                   

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formCompanyWebsite">
                                                <Form.Label>Employer Website</Form.Label>
                                                <Form.Control
                                                    type="url"
                                                    name="employer_website"
                                                    value={employerDetails.employer_website || ""}
                                                    placeholder="https://www.yourcompany.com"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formHQAddress">
                                                <Form.Label>HQ Address</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="address"
                                                    value={employerDetails.address || ""}
                                                    placeholder="Enter the main office address (e.g., 123 Main St)"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={3}>
                                            <Form.Group controlId="formYearFounded">
                                                <Form.Label>Year Founded</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name="year_founded"
                                                    value={employerDetails.year_founded || ""}
                                                    placeholder="Enter the year your company was established"
                                                    onChange={handleChange}
                                                    style={{ width: "95%" }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group controlId="formStatus">
                                                <Form.Label>Status</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="status"
                                                    value={employerDetails.status || ""}
                                                    onChange={handleChange}
                                                    style={{ width: "60%" }}
                                                >
                                                    <option value="">Select Status</option>
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formEmailDomain">
                                                <Form.Label>Email Domain</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="email_domain"
                                                    value={employerDetails.email_domain || ""}
                                                    placeholder="Enter your company’s email domain (e.g., abc.co)"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formSocialMediaLinks">
                                                <Form.Label>Social Media Links</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="social_media"
                                                    value={employerDetails.social_media || ""}
                                                    placeholder="Add links to your social media profiles (e.g., LinkedIn)"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formIndustry">
                                                <Form.Label>Industry</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="industry"
                                                    value={employerDetails.industry || ""}
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                >
                                                    <option value="">Select Industry</option>
                                                    {industries.map( ( industry, index ) => (
                                                        <option key={index} value={industry}>
                                                            {industry}
                                                        </option>
                                                    ) )}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formMaxJobLimit">
                                                <Form.Label>Maximum Job Limit</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="job_limit"
                                                    value={employerDetails.job_limit || ""}
                                                    placeholder="Max number of jobs this employee can post"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formStatus">
                                                <Form.Label>Maximum Number of Members</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="limit"
                                                    value={employerDetails.employee_limit || 0}
                                                    onChange={handleChange}
                                                    readOnly={isReadOnly}
                                                    style={{ width: "80%" }} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formAvailableJobsToPost">
                                                <Form.Label>Available Jobs To Post</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="job_count"
                                                    value={employerDetails.job_count ||0}
                                                    placeholder="Available Jobs to Post"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formEmployeeCount">
                                                <Form.Label>Employee Count</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="employee_count"
                                                    value={employerDetails.employee_count || ""}
                                                    placeholder="Total number of employees"
                                                    onChange={handleChange}
                                                    style={{ width: "80%" }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                  
                                   
                                    <Row className="mb-3">
                                        
                                        <CountryFillOne
                                            newState={employerDetails}
                                            setNewState={setEmployerDetails}
                                            onChangeHandler={handleChange}
                                            isReadOnly={isReadOnly}
                                            attachmentFiles={attachmentFiles}
                                            setAttachmentFiles={setAttachmentFiles}
                                            employerId={employerDetails.employer_id}
                                        />
                                    </Row>

                                    <div className="text-right">
                                        <Button
                                            type="button"
                                            className="btn btn-secondary activeBtn sizeBtn"
                                            onClick={handleSave}
                                        >
                        Save
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>

                </div>}
        </>
    );
};

export default EmployerDetails;