import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import util from "../../../utils/miniUtils";
import { Chip, TextField, Tooltip } from "@material-ui/core";
import SweetAlert from 'react-bootstrap-sweetalert';

function BlacklistModal( { id,show, handleClose, talent } ) {
    const [blacklistedReason, setBlacklistedReason] = useState( "" );
    const [showAlert, setShowAlert] = useState( false );

    useEffect( () => {
    }, [] );

    const handleSave = () => {
        setShowAlert( true );
    };

    const someAsyncFunction = async ( id ) => {
        const temp = { blacklisted_reason: blacklistedReason }; // Assuming blacklistedReason is defined
        try {
            if ( talent.blacklisted === 0 ) {
                // Call to blacklist the candidate
                const request = await util.axios.put( `/candidates/blacklistCandidate/${id}`, temp );
                const { error, msg } = request.data;
                if ( error ) throw msg;
                // Optionally, update the state to reflect the change
                // setTalent({ ...talent, blacklisted: 1 }); // Example of updating state
            } else if ( talent.blacklisted === 1 ) {
                // Call to remove the blacklist from the candidate
                const request = await util.axios.put( `/candidates/removeBlacklistCandidate/${id}` );
                const { error, msg } = request.data;
                if ( error ) throw msg;
                // Optionally, update the state to reflect the change
                // setTalent({ ...talent, blacklisted: 0 }); // Example of updating state
            }
        } catch ( error ) {
            util.handleError( error );
        }
    };


    const confirmSave = async () => {
        await someAsyncFunction( id );
        handleClose();
        setShowAlert( false );
    };

    const cancelSave = () => {
        setShowAlert( false );
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Blacklist Reason</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TextField
                        label="Blacklisted Reason"
                        multiline
                        rows={4}
                        value={blacklistedReason}
                        onChange={( e ) => setBlacklistedReason( e.target.value )}
                        fullWidth
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {showAlert && (
                <SweetAlert
                    title="Are you sure?"
                    onConfirm={confirmSave}
                    onCancel={cancelSave}
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                >
                    Do you want to save this reason?
                </SweetAlert>
            )}
        </>
    );
}

export default BlacklistModal;
