import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import util from '../../utils/miniUtils';
import { MuiThemeProvider } from '@material-ui/core';

const EmployerList = ( { handleEmployerClick,page,setPage,pageSize,setPageSize,data,loading,total,setSearch } ) => {
   
   
    const columns = [
        // { title: 'ID', field: 'employer_id' },
        
        { title: 'Name', field: 'company_legal_name' },
        { title: 'Status', field: 'status' },
        { title: 'Email', field: 'employer_email' },
        { title: 'Phone', field: 'employer_phone' },
        {
            title: '',
            field: '',
            render: rowData => (
                <button className='btn  activeBtn sizeBtn btn-secondary' onClick={() => handleEmployerClick( rowData )}>
                    
                    View
                </button>
            ),
        },
    ];

    

    return (
        <div className="mt-3">
            <MuiThemeProvider theme={util.defaultTableTheme} >
                
                <MaterialTable
                    className="company-list-table"
                    title=""
                    columns={columns}
                    data={data}
                    options={{
                        searchFieldAlignment: "left",
                        emptyRowsWhenPaging: false,
                        search: true,
                        selection:true,
                        exportButton: false,
                        paging: true, 
                        sorting: true,
                        pageSize: pageSize,
                        pageSizeOptions: [20, 50, 100], 
                        actionsColumnIndex: -1,
                        maxBodyHeight: 'calc(100vh - 320px)',
                    }}
                    isLoading={loading}
                    page={page}
                    totalCount={total}
                    onChangePage={newPage => {
                        setPage( newPage );
                    }}
                    onChangeRowsPerPage={( pageSize ) => {
                        setPageSize( pageSize );
                        setPage( 0 ); 
                    }}
                    onSearchChange={query => {
                        setSearch( query );
                        setPage( 0 );
                    }}                       
                />
            </MuiThemeProvider></div>
    );
};

export default EmployerList;