import { PositionsStore } from "../../stores/PositionsStore";
import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt,
    faClock,
    faCommentDollar,
    faCopy,
    faCropAlt,
    faDollarSign,
    faLevelUpAlt,
    faMapMarkerAlt,
    faMoneyBill,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import util from "../../../utils/miniUtils";
import ApplyModal from "../../modals/apply_modal/ApplyModal";
import DrawBackPosition from "../../../jobs/components/jobLists/talent/DrawBackPosition";
import ModalCX from "../../modals/ModalCX";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { Facebook, LinkRounded, LinkedIn, Twitter } from "@material-ui/icons";

export default function PositionTitleSecondary( { job } ) {
    const position = PositionsStore.useState( state => state );
    const [isDrawingBack, setIsDrawingBack] = useState( false );
    const [employer, setEmployer] = useState( null );
    const [loading, setLoading] = useState( true );
    const [error, setError] = useState( null );
    const [open, setOpen] = useState( false );
  

    function paymentUtility( amount, country ) {
        if ( util.noValues.includes( amount ) ) return "~";
        return util.currencyFormat( amount.toString(), country );

    }
    const copyToClipboard = () => {
        const url = window.document.location.origin + `/careers?swe=${util.uuidv4()}&job=${job.id}&fs=true`;
        navigator.clipboard.writeText( url ).then( null );
        setOpen( true );
    };
    const handleClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        setOpen( false );
    };
    
    
    useEffect( () => {
        const fetchEmployerDetails = async () => {
            if ( !job?.employer_id ) {
                setEmployer( null ); // Ensure employer state is reset if no employer ID is present
                setLoading( false );
                return;
            }
    
            try {
                setLoading( true );
                const employerId = job.employer_id;
                const request = await util.axios.get( `/employers/${employerId}` );
                const { error, msg, data } = request.data;
    
                if ( error ) throw new Error( msg );
    
                setEmployer( data );
            } catch ( error ) {
                util.handleError( error );
                setError( "Failed to fetch employer details." );
            } finally {
                setLoading( false );
            }
        };
    
        fetchEmployerDetails();
    }, [job?.employer_id] ); // Only run effect when employer_id changes
    
    return (
        <Fragment>

            <div className="col-md-12 ml-2">
                <div className="jobPublicStyle d-flex justify-content-between">
                    <div className="mb-2 ">
                        <div className="mb-2">
                            {job?.employer_id && employer?.company_logo_file  ? (
                                <div>
                                <b className="Job_DetailsCard">Company Logo:</b>
                                    <img
                                        style={{ maxWidth: "100px", marginLeft: "10px" }}
                                        src={`${util.baseUrl()}${util.AWSURL()}/employer_logos/${util.user.employer_id}/${employer.company_logo_file}`}
                                        alt={employer.company_legal_name}
                                    />
                                                               
                                </div>
                            ) : null}
                        </div>
                        {job?.employer_id && employer?.company_legal_name && (
                            <div className="mb-2">
                                <FontAwesomeIcon icon={faCropAlt} className="iconGray" style={{ marginRight: "8px" }} />
                                <b className="Job_DetailsCard">Company Legal name:</b> {employer.company_legal_name}
                            </div>
                        )}

                        <div className="mb-2">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="  iconGray" style={{ marginRight: "8px" }} />
                            <b className="Job_DetailsCard ">Location:</b> {util.location( job.country, job.state, job.city )}
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faLevelUpAlt} className="  iconGray" style={{ marginRight: "11px" }} />
                            <b className="Job_DetailsCard " >Level:</b> {job["level"]}
                        </div>
                    </div>


                </div>

                <div className="jobPublicStyle d-flex justify-content-between">
                    <div>
                        <div className="mb-2 ">
                            <FontAwesomeIcon icon={faClock} className="iconGray " />
                            <b className="Job_DetailsCard">Employment Type:</b>
                            <p>{job?.employment_type ? job?.employment_type : "Not Disclosed"}</p>

                        </div>
                        {( job.employment_type !== "Fulltime" && job.employment_type !== "Fulltime " ) ? <div className="mb-2 ">
                            <FontAwesomeIcon icon={faClock} className="iconGray " />
                            <b className="Job_DetailsCard">Duration:</b> <u>{job.duration}</u>
                        </div> : ""}
                        <div className="mb-2 ml-1" >
                            <FontAwesomeIcon icon={faDollarSign} className=" iconGray " style={{ fontSize:"18px" }} />
                            <b className="Job_DetailsCard">Salary Range:</b> {util.buyFromBuyTo( job.buy_from, job.buy_to, job.country ) !== "~" ? `${util.buyFromBuyTo( job.buy_from, job.buy_to, job.country )} ${job.sell_rate_type}` : "Not disclosed" }
                        </div>
                    </div>  <div className="jobPublicStyle justify-content-end mr-5">
                        {job["applied"] ?
                            <Fragment>
                                {[10, 19, 11].indexOf( job["statusCandidate"] ) === 1 || job["talent_dropped"] ?
                                    <p>You withdrew the application</p>
                                    :
                                    <button className="btn btn-sm btn-danger float-right"
                                        onClick={() => setIsDrawingBack( true )}
                                    >
                                        <FontAwesomeIcon icon={faTimes} className="mr-2" />
                                        Withdraw
                                    </button>
                                }
                            </Fragment>
                            :
                            <button
                                onClick={() => PositionsStore.update( s => {
                                    s.job=job;
                                    s.applyModal = true;
                                } )}
                                className="btn btn-sm btn-secondary activeBtn sizeBtn" >
                                Apply
                            </button>
                        }
                    </div>
                </div>


            </div>


            <ModalCX
                title="Withdraw Position"
                show={isDrawingBack}
                handleClose={() => setIsDrawingBack( false )}
            >
                <DrawBackPosition
                    closeModal={() => setIsDrawingBack( false )}
                    jd={job}
                    refreshTable={() => window.location.reload()}
                    setIsDrawingBack={() => setIsDrawingBack( false )}
                />
            </ModalCX>

            {position.applyModal ? <ApplyModal /> : ""}
            {/*{position.createAccount ? <Register/>: null}*/}
            {/*{position.loginModal ? <Login/>: null}*/}
        </Fragment>
    );
}



