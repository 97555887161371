import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import MemberList from './MemberList';
import { Paper } from '@material-ui/core';
import EmployerList from './EmployerList';
import EmployerDetails from './EmployerDetails';
import util from '../../utils/miniUtils';


const Employer = () => {
    const [showForm, setShowForm] = useState( false );
    const [selectedEmployer, setSelectedEmployer] = useState( null );
    const [data, setData] = useState( [] );
    const [pageSize, setPageSize] = useState( 20 );
    const [page, setPage] = useState( 0 );
    const [total, setTotal] = useState( 0 );
    const [search, setSearch] = useState( "" );
    const [loading, setLoading] = useState( false );
    const [status, setStatus] = useState( "All Status" );
    // const handleClose = () => {
    //     setShowForm( false );
    //     setSelectedEmployer( null );
    // };
    const onBackClick = () => {
        setShowForm( false );    
    };

    const handleNewEmployer = () => {
        setSelectedEmployer( null );
        setShowForm( true );
    };

    const handleEmployerClick = ( value ) => {
        setSelectedEmployer( value );
        setShowForm( true );
    };
    const getEmployerList = async () => {
        setLoading( true );
        try {
            const request = await util.axios.get( `/employers?page=${page}&pageSize=${pageSize}&status=${status}&search=${search}` );
                
            const { data, error, msg, total } = request.data;
            console.log( data );
            if( error ) throw msg;
            setData( data );
            setTotal( total );
            setLoading( false );
        } catch ( error ) {
            util.handleError( error );
            setLoading( false );
        }
    };
    
    useEffect( () => {
        getEmployerList();
    },[page, pageSize, status] );

    return (
        <>
            <div className="d-flex mt-3">
                {!showForm ? <button className="btn btn-primary" onClick={handleNewEmployer}>
                    <FontAwesomeIcon icon={faPlus} />
                    <span className="ml-1">Add Employer</span>
                </button> : ""}
                {!showForm ?  <div className='ml-3' style={{ width: '10%' }}>
                    <select className="form-control" style={{ borderRadius: '5px' }} placeholder="Status"  value={status} onChange={( e ) => setStatus(  e.target.value )}>
                        <option value="All Status">All Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">InActive</option>
                    </select></div>:""}
            </div>
            {!showForm ? <EmployerList handleEmployerClick={handleEmployerClick} data={data} pageSize={pageSize} page={page} search={search} setPage={setPage} setPageSize={setPageSize} setSearch={setSearch} total={total} loading={loading}/> : 
                <EmployerDetails onBackClick={onBackClick} selectedEmployer={selectedEmployer} />}
            
        </>
    );
};

export default Employer;